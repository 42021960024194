import { ChangeDetectionStrategy, Component, Inject, Signal, contentChild } from '@angular/core';
import { CustomDialogContentDirective } from '../../directives/custom-dialog-content.directive';
import { NapaDialogConfig } from '../../models/dialog.models';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'napa-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrl: './dialog-body.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogBodyComponent {
  protected customContent: Signal<CustomDialogContentDirective | undefined> =
    contentChild(CustomDialogContentDirective);
  body: SafeHtml = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly config: NapaDialogConfig,
    private sanitizer: DomSanitizer
  ) {
    this.body = this.sanitizer.bypassSecurityTrustHtml(this.config.body ?? '');
  }
}
